:root {
  --accent_text_color: #50a7ea;
  --bg_color: #222b34;
  --button_color: #50a7ea;
  --button_text_color: #ffffff;
  --destructive_text_color: #ff0000;
  --header_bg_color: #0f1217;
  --hint_color: #b1c3d5;
  --link_color: #50a7ea;
  --secondary_bg_color: #0f1217;
  --section_bg_color: #222b34;
  --section_header_text_color: #b1c3d5;
  --subtitle_text_color: #ffffff;
  --text_color: #ffffff;
  --gray_button_bg: #868686;

  /* Temporary hack to mimick the smaller size of the Telegram app */
  /* TODO: Remove before release */
  /* --tg-viewport-height: 50vh;
  --tg-viewport-width: 50vw;
  height: var(--tg-viewport-height);
  width: var(--tg-viewport-width); */

  background-color: var(--secondary_bg_color);
}
